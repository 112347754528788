<template>
  <div id="app">
    <headerTab></headerTab>
    <router-view></router-view>
  </div>
</template>

<script>
import headerTab from "./components/headerTab/headertab.vue"
export default {
  name: 'App',
  components:{
    headerTab
  }
}
</script>

<style lang="less">
.flex{
  display: flex;
}
.flex-dc{
  flex-direction: column;
}
.flex-afe{
  align-items: flex-end;
}
.flex-ac{
  align-items: center;
}
.flex-jc{
  justify-content: center;
}
.flex-jsa{
  justify-content: space-around;
}
.flex-afs{
  align-items: flex-start;
}
.flex-afe{
  align-items: flex-end;
}
.flex-jfe{
  justify-content: flex-end;
}
.flex-jsb{
  justify-content: space-between;
}
.flex-1{
  flex: 1;
}
.pointer{
  cursor: pointer;
}
.num-font-family{
  font-family: 'din-bold';
}
.pregular-font-family{
  font-family: 'PingFang-Regular';
}
.pbold-font-family{
  font-family: 'PingFang-Bold';
}
body{
  min-width: 1450px;
  // overflow-x: hidden;
}
#app{
  font-family: 'PingFang-Regular';
  position: relative;
}
</style>
