<template>
  <div class="header-section flex flex-ac flex-jc">
    <div class="header-box flex flex-ac flex-jsb">
      <div class="header-img-box pointer" @click="homeClick">
        <img src="../../assets/images//logo-header.png" alt="" class="header-img">
      </div>
      <div class="flex">
        <div v-for="(item,index) in lists" :key="index" :class="['head-list','pointer',index == headIndex ? 'head-list-active pbold-font-family':'']" @mouseenter="mouseenter(item.id,index)"
        @click="headTap(item)">
          {{item.title}}<a-icon type="down" style="margin-left: 5px;font-size: 10px;" v-if="item.id == 1 || item.id == 2" />
        </div>
      </div>
      <div class="phone-box">
        <img src="../../assets/images//phone.png" alt="" class="phone-icon">4009-988-941
      </div>
      <div class="button-box">
        <div class="button-zixun pointer" @click="phoneClick">立即咨询</div>
      </div>
    </div>
    <div class="hover-box" v-if="isHover" @mouseleave="mouseleaveHover">
      <div class="hover-section flex flex-jc">
        <template >
          <div class="h-b-1 flex flex-dc flex-afe">
            <template v-if="hoverId == '1'">
              <div v-for="(item,index) in productList" :key="index" :class="['h1-list pointer',index == hoverIndex ? 'h1-list-active': '']"
                 @mouseenter="mouseenterHover(index,item)">
                {{ item.title }}
              </div>
            </template>
            <template v-if="hoverId == '2'">
              <div v-for="(item,index) in solveList" :key="index">
                <div style="font-size: 18px;color: #000;margin-bottom: 7px;">{{ item.title }}</div>
                <div style="font-size: 12px;color: #70717B;">{{ item.tips }}</div>
              </div>
            </template>
          </div>
          <div class="h-b-other flex">
            <template v-if="hoverInfo && hoverInfo.children && hoverInfo.children.length">
              <template v-if="hoverId == '1'">
                <div class="h-b-2">
                  <template>
                    <div v-for="(item,index) in hoverInfo.children" :key="index" class="h2-list flex flex-ac">
                      <!-- <span :class="index < 3 ? 'span1' : 'span2'">{{ index + 1 }}</span> -->
                      <span class="sign"></span>
                      <span>{{ item.title }}</span>
                      <span class="span3" v-if="item.isNew"><p class="span3p">NEW</p></span>
                    </div>
                  </template>
                </div>
              </template>
              <template v-if="hoverId == '2'">
                <div class="h2-list-2 flex">
                  <div class="h2-list-2-box flex flex-ac" v-for="(item,index) in hoverInfo.children" :key="index" >
                    <img :src="require('@/assets/images/'+item.imgUrl)" alt="" class="h2-l-2-img">{{ item.title }}
                  </div>
                </div>
              </template>
              <div class="h-b-3">
                <img src="@/assets/images/product_1.jpg" alt="" class="h3-img">
                <a-button class="h3-btn" type="primary" @click="phoneClick">立即咨询</a-button>
              </div>
            </template>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'headertab',
  data() {
    return {
      current: [],
      lists:[
        {
          id: 1,
          title: '产品'
        },{
          id: 2,
          title: '解决方案'
        },{
          id: 3,
          title: '案例'
        },{
          id: 4,
          title: '下载'
        },{
          id: 5,
          title: '学堂',
          linkUrl: 'https://school.zxinyun.com/cms'
        },{
          id: 6,
          title: '服务商',
          linkUrl: 'https://p.zxinyun.com'
        },{
          id: 7,
          title: '商户',
          linkUrl: 'https://b.zxinyun.com'
        },{
          id: 8,
          title: '关于吱信'
        }
      ],
      productList: [
        {
          title: '收银系统',
          children:[
            {
              title: 'IOT蜻蜓收银系统',
              isNew: true
            },{
              title: 'PC收银系统'
            },{
              title: '安卓收银系统'
            },{
              title: '手持POS收银系统'
            },{
              title: '收银盒子'
            }
          ]
        },{
          title: '管理系统',
          children:[
            {
              title: '吱信商户后台',
            },{
              title: '吱信商户助手'
            },{
              title: '吱信服务商后台'
            },{
              title: '吱信服务商工作台'
            }
          ]
        },{
          title: '小程序',
          children:[{
              title: '吱信C端小程序',
            },{
              title: '小程序装修'
            },{
              title: '微信小程序'
            },{
              title: '外卖小程序'
            }]
        },{
          title: '功能应用',
          children:[{
              title: 'IOT刷脸入会',
            },{
              title: '聚合支付'
            },{
              title: '会员管理'
            },{
              title: '聚合团购'
            },{
              title: '连锁门店'
            },{
              title: '商户对账'
            },{
              title: '扫码点餐'
            },{
              title: '支付宝次卡周期付'
            }]
        }
      ],
      solveList:[
        {
          title: '解决方案',
          tips: '行业解决方案，助力用户快速增长。',
          children: [
            {
              imgUrl: 'section3_12.png',
              title: '健身解决方案'
            },{
              imgUrl: 'section3_3.png',
              title: '教培解决方案'
            },{
              imgUrl: 'section3_4.png',
              title: '餐饮解决方案'
            },{
              imgUrl: 'section3_5.png',
              title: '零售解决方案'
            },{
              imgUrl: 'section3_6.png',
              title: '休娱解决方案'
            },{
              imgUrl: 'section3_7.png',
              title: '丽人解决方案'
            },{
              imgUrl: 'section3_8.png',
              title: '宠物解决方案'
            }
          ]
        }
      ],
      headIndex: -1,
      isHover: false,
      hoverList: [],
      hoverIndex: 0,
      hoverInfo: {},
      hoverId: '',
    };
  },
  methods:{
    homeClick(){
      this.$bus.$emit("homeClick")
    },
    phoneClick(){
      this.mouseleaveHover()
      this.$bus.$emit("phoneClick")
    },
    mouseenter(id,index){
      this.headIndex = index
      if(id == 1 || id == 2){
        this.isHover = true
        this.hoverIndex = 0
        this.hoverInfo = id == 1 ? this.productList[0] : this.solveList[0]
        this.hoverId = id
      }else{
        this.isHover = false
        this.hoverIndex = -1
        this.hoverInfo = {}
        this.hoverId = ''
      }
    },
    mouseenterHover(index,item){
      this.hoverIndex = index
      this.hoverInfo = item
    },
    mouseleaveHover(){
      this.isHover = false
      this.hoverIndex = -1
      this.hoverInfo = {}
      this.hoverId = ''
      this.headIndex = -1
    },
    headTap(item){
      if(item.linkUrl){
        window.open(item.linkUrl)
        this.headIndex = -1
      }else{
        this.$bus.$emit("headTap")
      }
    }
  }
};
</script>
<style lang="less" scoped>
.header-box{
  box-sizing: border-box;
  // padding-top: 23px;
  padding-top: 10px;
  max-width: 1480px;
  min-width: 1110px;
}
.header-img-box{
  height: 48px;
  .header-img{
    width: 99px;
    height: 33px;
    margin-right: 45px;
    margin-top: 2px;
  }
}
.head-list{
  margin-right: 31px;
  color: #323A46;
}
.head-list:last-child{
  margin-right: 0;
}
.head-list-active{
  color: #3470FF;
}
.phone-box{
  margin: 0 17px 0 120px;
  .phone-icon{
    width: 16px;
    height: 16px;
    margin-right: 7px;
  }
}
.button-box{
  .button-zixun{
    width: 88px;
    height: 30px;
    border: #3370FF 1px solid;
    border-radius: 15px;
    color: #3370FF;
    font-size: 14px;
    text-align: center;
    line-height: 28px;
  }
}

.header-section{
}
.hover-box{
  position: absolute;
  top: 59px;
  left: 0;
  width: 100%;
  // background: #fff;
  background: linear-gradient(to right,#ECF2FD 50%,#fff 50%);
  z-index: 9999;
  .hover-section{
    max-width: 1069px;
    margin: auto;
  }
  .h-b-1{
    width: 179px;
    background: #ECF2FD;
    color: #000000;
    font-size: 16px;
    box-sizing: border-box;
    padding: 35px 16px 80px 0;
    .h1-list{
      width: 163px;
      height: 37px;
      margin-bottom: 33px;
      text-align: center;
      line-height: 37px;
    }
    .h1-list-active{
      background: #D9E6FD;
      color: #3370FF;
    }
  }
  .h-b-other{
    width: 890px;
    background: #FFF;
    color: rgba(0, 0, 0, 1);
  }
  .h-b-2{
    width: 668px;
    box-sizing: border-box;
    padding: 41px 0 75px 30px;
    .h2-list{
      height: 20px;
      line-height: 20px;
      margin-bottom: 31px;
      .sign{
        display: block;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #3370FF;
        margin-right: 10px;
      }
      .span1{
        color: #3370FF;
        font-weight: 600;
        margin-right: 18px;
      }
      .span2{
        color: #70717B;
        margin-right: 18px;
        font-weight: 400;
      }
      .span3{
        display: block;
        width: 32px;
        height: 16px;
        border-radius: 4px;
        background: #FFC538;
        color: #fff;
        line-height: 16px;
        text-align: center;
        margin-left: 6px;
        .span3p{
          font-size: 12px;
          transform: scale(0.91,0.91);
          margin-bottom: 0;
        }
      }
    }
  }
  .h2-list-2{
    flex-wrap: wrap;
    align-content: flex-start;
    margin-top: 42px;
    margin-left: 25px;
    .h2-list-2-box{
      width: 315px;
      height: 32px;
      margin-bottom: 18px;
    }
    .h2-l-2-img{
      width: 20px;
      height: 20px;
      margin-right: 18px;
    }
  }
  .h-b-3{
    margin-top: 34px;
    width: 222px;
    height: 277px;
    margin-bottom: 20px;
    position: relative;
    .h3-img{
      width: 222px;
      height: 277px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .h3-btn{
      position: absolute;
      bottom: 14px;
      left: 20px;
      border-radius: 8px;
      background-color: #3370FF;
      width: 75px;
      height: 30px;
      padding: 0;
    }
  }
}
</style>