import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import Antd from 'ant-design-vue';
import "ant-design-vue/dist/antd.css";
import '@/assets/fonts/font.css'
Vue.use(Antd)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
