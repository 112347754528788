import Vue from "vue";
import VueRouter from "vue-router";
import router from "./route";
// import echartsRouters from './practice/echarts'

// 注册vue-router中的所有组件
Vue.use(VueRouter);

const allRouter = [...router];
const routers = new VueRouter({
  mode: 'history',
  routes: allRouter
});

export default routers;


